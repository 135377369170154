// scroll bar
import 'simplebar/src/simplebar.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Motor } from '@motor-js/core';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import App from './App';

// ----------------------------------------------------------------------
const myTheme = {
  global: {
    login: {
      header: 'autone.io',
      body: 'Please login to access your analytics',
      buttonColor: '#3366FF'
    }
  }
};

ReactDOM.render(
  <React.StrictMode>
    <Motor
      config={{
        host: 'repa8nuc00b2vcz.eu.qlikcloud.com',
        secure: true,
        port: null,
        prefix: '',
        appId: '2e5339c1-ac9f-4521-a76f-4d58a88a2119',
        webIntId: '9ChazEO02jkNEjOet9CEaHcEeE-_BcDP',
        qcs: true
      }}
      theme={myTheme}
    >
      <App />
    </Motor>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
