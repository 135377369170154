// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const ICONS = {
  page: getIcon('ic_page'),
  dashboard: getIcon('ic_dashboard'),
  user: getIcon('ic_user'),
  map: getIcon('ic_map'),
  ecommerce: getIcon('ic_ecommerce')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Views',
    items: [
      {
        title: 'Retail Performance',
        href: '/',
        icon: ICONS.page
      }
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'Filters',
    items: [
      {
        title: 'Drop',
        href: PATH_DASHBOARD.app.root,
        icon: ICONS.dashboard,
        items: [
          {
            title: 'gender',
            icon: ICONS.user,
            qlikDefinition: [
              { qField: '[gender]', qLabel: 'gender' },
              {
                qField: '=if(count({<[gender] = >} [gender])>0,1,0)',
                qLabel: 'filter'
              }
            ]
          },
          {
            title: 'country',
            icon: ICONS.map,
            qlikDefinition: [
              { qField: '[country]', qLabel: 'country' },
              {
                qField: '=if(count({<[country] = >} [country])>0,1,0)',
                qLabel: 'filter'
              }
            ]
          },
          {
            title: 'MarkDown',
            icon: ICONS.ecommerce,
            qlikDefinition: [
              { qField: '[MarkDown]', qLabel: 'MarkDown' },
              {
                qField: '=if(count({<[MarkDown] = >} [MarkDown])>0,1,0)',
                qLabel: 'filter'
              }
            ]
          }
        ]
      }
    ]
  }
];

export default sidebarConfig;
