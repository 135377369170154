import React, { useState, useContext } from 'react';
import {
  alpha,
  makeStyles,
  experimentalStyled as styled
} from '@material-ui/core/styles';
import {
  Box,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  List
} from '@material-ui/core';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { Icon } from '@iconify/react';
import { useHyperCube, EngineContext } from '@motor-js/core';

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&.isActiveRoot': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: 'absolute',
      backgroundColor: theme.palette.primary.main
    }
  },
  '&.isActiveSub': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    '& .subIcon:before': {
      transform: 'scale(2)',
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  listItemText: {
    fontSize: '0.7em'
  },
  nested: {
    paddingLeft: theme.spacing(5),
    textTransform: 'capitalize',
    fontSize: '14px'
  },
  label: {
    ...theme.typography.body2
  }
}));

function capitalizeFirstLetter(string) {
  let copy = string.slice();
  copy = copy.charAt(0).toUpperCase() + copy.substr(1).toLowerCase();
  return copy;
}

export default function FilterQlik(props) {
  const [show, setShow] = useState(false);
  const classes = useStyles();
  // const isSubItem = level > 0;

  const handleShow = () => {
    setShow((show) => !show);
  };

  const { title, icon, info, qlikDefinition } = props.props;
  const { engine } = useContext(EngineContext);

  const handleFilterSelect = async (field, values) => {
    const sel = await [values].map((d) => ({ qText: d }));
    const qDoc = await engine;
    const qField = await qDoc.getField(field);

    qField.selectValues(sel, true, true);
  };

  const cols = qlikDefinition;
  const columnSortOrder = [1, 0];
  const sortDirection = 'ASCENDING';

  const { qData } = useHyperCube({
    engine,
    cols,
    columnSortOrder,
    sortDirection
  });

  return (
    <>
      <ListItemStyle
        button
        disableGutters
        onClick={handleShow}
        className={show ? 'isActiveRoot' : ''}
      >
        <ListItemIcon>{icon && icon}</ListItemIcon>
        <ListItemText disableTypography primary={title} />
        {info && info}
        <Box
          component={Icon}
          icon={show ? arrowIosDownwardFill : arrowIosForwardFill}
          sx={{ width: 16, height: 16, ml: 1 }}
        />
      </ListItemStyle>

      <Collapse in={show} style={{ maxHeight: '200px', overflowY: 'scroll' }}>
        <List className={classes.root}>
          {qData !== null
            ? qData.qMatrix.map((attribute) => (
                <ListItem
                  key={title + attribute[0].qText}
                  button
                  onClick={() => handleFilterSelect(title, attribute[0].qText)}
                  className={classes.nested}
                >
                  <ListItemText
                    key={attribute[0].qText}
                    primary={capitalizeFirstLetter(attribute[0].qText)}
                    className={classes.nested}
                    style={{ fontSize: '14px' }}
                  />
                </ListItem>
              ))
            : null}
        </List>
      </Collapse>
    </>
  );
}
