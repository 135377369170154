import { Icon } from '@iconify/react';
import React, { useState, useContext } from 'react';
import {
  alpha,
  makeStyles,
  experimentalStyled as styled
} from '@material-ui/core/styles';
import { EngineContext, useSearch } from '@motor-js/core';
import _ from 'lodash';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import {
  Box,
  Input,
  Slide,
  InputAdornment,
  ClickAwayListener,
  ListItem,
  List
} from '@material-ui/core';

// components
import { MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

const SearchbarresultsStyle = styled('div')(({ theme }) => ({
  color: 'black',
  cursor: 'pointer',
  bottom: -APPBAR_MOBILE,
  [theme.breakpoints.up('md')]: {
    bottom: -APPBAR_DESKTOP - 100,
    padding: theme.spacing(0, 0)
  },
  width: '100%',
  position: 'absolute',
  backgroundColor: 'white',
  zIndex: 999999,
  boxShadow: theme.customShadows.z8,
  maxHeight: '200px',
  overflowY: 'scroll',
  padding: '0px',
  borderRadius: '5px',
  left: '0px'
}));

// const ListItemStyle = styled(ListItem)(({ theme }) => ({
//   ...theme.typography.body2,
//   height: 48,
//   textTransform: 'capitalize',
//   color: theme.palette.text.secondary,
//   padding: '10px',
//   '&.isActiveRoot': {
//     color: theme.palette.primary.main,
//     fontWeight: theme.typography.fontWeightMedium,
//     backgroundColor: alpha(
//       theme.palette.primary.main,
//       theme.palette.action.selectedOpacity
//     ),
//     '&:before': {
//       top: 0,
//       right: 0,
//       width: 3,
//       bottom: 0,
//       content: "''",
//       position: 'absolute',
//       backgroundColor: theme.palette.primary.main
//     }
//   },
//   '&.isActiveSub': {
//     color: theme.palette.text.primary,
//     fontWeight: theme.typography.fontWeightMedium,
//     '& .subIcon:before': {
//       transform: 'scale(2)',
//       backgroundColor: theme.palette.primary.main
//     }
//   }
// }));

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  listItemText: {
    fontSize: '0.7em'
  },
  nested: {
    paddingLeft: theme.spacing(0, 1),
    width: '100%',
    textTransform: 'capitalize',
    fontSize: '14px',
    borderRadius: '3px',
    '&:hover': {
      background: '#f5f5f5'
    }
  },
  label: {
    ...theme.typography.body2
  }
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();

  const { engine } = useContext(EngineContext);

  const [searchValue, setSearchValue] = React.useState('');

  const dimensions = [];
  const qCount = 100;
  const qGroupItemCount = 100;

  const { searchResults } = useSearch(
    {
      engine,
      searchValue,
      dimensions,
      qCount,
      qGroupItemCount
    },
    [searchValue]
  );

  // console.log('Qlik search results are', searchResults);

  const handleSearchChange = (e) => {
    // console.log('search is typed', e.target.value);
    setSearchValue(e.target.value);
  };

  const handleResultSelect = async (dimension, values) => {
    const field = values.split(',');

    const sel = await field.map((d) => ({ qText: d }));
    const qDoc = await engine;
    const qField = await qDoc.getField(dimension);
    qField.selectValues(sel, true, true);
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchValue('');
  };

  try {
    console.log(searchResults);
  } catch {
    console.log('not defined');
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <MIconButton onClick={handleOpen}>
            <Icon icon={searchFill} width={20} height={20} />
          </MIconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <div>
              <Input
                autoFocus
                fullWidth
                disableUnderline
                placeholder="Search…"
                startAdornment={
                  <InputAdornment position="start">
                    <Box
                      component={Icon}
                      icon={searchFill}
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                }
                onChange={_.debounce(handleSearchChange, 500, {
                  leading: true
                })}
                sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
              />
            </div>
            {searchValue !== '' ? (
              <SearchbarresultsStyle>
                <List className={classes.root}>
                  {searchResults !== undefined && searchValue !== ''
                    ? searchResults.qSearchGroupArray.map((searchItem) => (
                        <ListItem
                          key={Math.floor(Math.random() * 100000).toString()}
                          className={classes.nested}
                          onClick={() =>
                            handleResultSelect(
                              searchItem.qItems[0].qIdentifier,
                              _.map(
                                searchItem.qItems[0].qItemMatches,
                                'qText'
                              ).join(',')
                            )
                          }
                          aria-hidden="true"
                        >
                          <div
                            style={{
                              paddingLeft: '20px',
                              paddingRight: '20px'
                            }}
                          >
                            <div
                              style={{
                                height: '22px',
                                minWidth: '22px',
                                lineHeight: 0,
                                borderRadius: '8px',
                                cursor: 'default',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                                display: 'inline-flex',
                                justifyContent: 'center',
                                padding: '0px 8px',
                                color: '#637381',
                                fontSize: '0.75rem',
                                fontWeight: '700',
                                backgroundColor: '#F3F3F3',
                                marginBottom: '5px'
                              }}
                            >
                              {searchItem.qItems[0].qIdentifier}
                            </div>
                            <div style={{ paddingLeft: '5px' }}>
                              {_.map(
                                searchItem.qItems[0].qItemMatches,
                                'qText'
                              ).join(',')}
                            </div>
                          </div>
                        </ListItem>
                      ))
                    : null}
                </List>
              </SearchbarresultsStyle>
            ) : null}
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
