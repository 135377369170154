import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import React, { useContext } from 'react';
import { EngineContext, useSelectionObject } from '@motor-js/core';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, AppBar, Hidden, Toolbar, IconButton } from '@material-ui/core';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

const SearchPillContainer = styled('div')(({ theme }) => ({
  width: '100%',
  // backgroundColor: 'blue',
  // height: '80px',
  padding: theme.spacing(0, 0),
  justifyContent: 'flex-end',
  display: 'flex',
  alignItems: 'right'
}));

const SearchPill = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '8px',
  padding: theme.spacing(1, 1),
  marginLeft: theme.spacing(1),
  width: 'fit-content',
  cursor: 'pointer',
  color: '#212B36',
  fontSize: '12px',
  boxShadow:
    '0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)'
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { engine } = useContext(EngineContext);

  const { qLayout } = useSelectionObject({ engine });

  const handleFilterClear = async (dimension) => {
    const qDoc = await engine;
    const qField = await qDoc.getField(dimension);
    qField.clear();
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        <Hidden lgUp>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </Hidden>

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'right',
            '& > *:not(:first-of-type)': {
              ml: { xs: 0.5, sm: 2, lg: 3 }
            },
            paddingRight: '20px'
          }}
        >
          <SearchPillContainer>
            {qLayout === null
              ? null
              : qLayout.qSelectionObject.qSelections.map((item) => (
                  // <span
                  //   key={item.qField}
                  //   // onClick={() => handleFilterClear(item.qField)}
                  // >
                  //   {item.qSelected}
                  // </span>
                  <SearchPill
                    onClick={() => handleFilterClear(item.qField)}
                    key={item.qField}
                  >
                    {item.qSelected}
                  </SearchPill>
                ))}
          </SearchPillContainer>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
              ml: { xs: 0.5, sm: 2, lg: 3 }
            }
          }}
        >
          {/* <LanguagePopover />
          <NotificationsPopover /> */}
          <AccountPopover />
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
